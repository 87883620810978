import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const IconArrowRightAlt = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path xmlns="http://www.w3.org/2000/svg" d="M0 0h24v24H0z" fill="none" />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z"
      />
    </SvgIcon>
  );
};

export default IconArrowRightAlt;
