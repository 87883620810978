import React, { useState, createRef, useEffect } from 'react';
import Files from 'react-files';
import { Box, IconButton, Snackbar } from '@material-ui/core';
import IconClose from '@material-ui/icons/Close';
import FilePreview from './FilePreview';
import IconCloud from '../../Common/Icons/IconCloud';

interface IUpload {
  className: string;
  maxFiles?: number;
  label?: string;
  onChangeInputFile?: (files: Files[]) => void;
}

export default function FileUpload(props: IUpload) {
  const { className, maxFiles, label, onChangeInputFile } = props;
  const inputRef = createRef<any>();
  const initialFile: File[] = [];
  const [files, setFiles] = useState(initialFile);
  const [snackBar, setSnackBar] = useState(false);
  const [messageSnackBar, setMessageSnackBar] = useState('');

  useEffect(() => {
    if (onChangeInputFile) {
      onChangeInputFile(files);
    }
  }, [files]);

  const handleClose = () => {
    setSnackBar(false);
  };
  const onFilesChange = (fileUpload: File[]) => {
    setFiles(fileUpload);
  };
  const handleRemove = (index: number) => {
    setFiles((files) => {
      const copy: File[] = [];
      files.map((file: File, i: number) => {
        if (i !== index) copy.push(file);
        return copy;
      });
      return copy;
    });

    if (inputRef && inputRef.current) {
      let newFiles: File[] = [];
      inputRef.current.state.files.splice(index, 1);
      newFiles = inputRef.current.state.files;
      inputRef.current.state.files = newFiles;
    }
  };

  const onFilesError = (error: any) => {
    let message = '';
    switch (error.code) {
      case 1:
        message = 'Type de fichier invalide';
        break;
      case 2:
        message = 'Le fichier est trop grand';
        break;
      case 3:
        message = 'Le fichier est trop petit';
        break;
      case 4:
        message = 'Le nombre maximun de fichier atteint';
        break;
    }
    setSnackBar(true);
    setMessageSnackBar(message);
  };

  return (
    <Box>
      {files.length !== maxFiles ? (
        <Files
          ref={inputRef}
          className={className}
          onChange={onFilesChange}
          onError={onFilesError}
          accepts={['image/*', '.pdf', '.doc', '.docx']}
          multiple
          maxFiles={maxFiles ? maxFiles : 1}
          maxFileSize={10000000}
          minFileSize={0}
          clickable
        >
          <Box>
            <IconButton color="inherit">
              {files.length < (maxFiles ? maxFiles : 1) ? (
                <IconCloud width="24" height="24" viewBox="0 0 24 24" />
              ) : (
                ''
              )}
            </IconButton>
          </Box>
          {label ? label : 'joindre un ou plusieurs fichiers'}
        </Files>
      ) : (
        ''
      )}
      {files && files.length ? (
        <Box>
          {files.map((file: File, index: number) => {
            return (
              <FilePreview
                file={file}
                key={index}
                handleRemove={() => handleRemove(index)}
              />
            );
          })}
        </Box>
      ) : (
        ''
      )}
      <Snackbar
        open={snackBar}
        message={messageSnackBar}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={handleClose}
        action={
          <IconButton onClick={handleClose} size="small" color="inherit">
            <IconClose />
          </IconButton>
        }
      />
    </Box>
  );
}
