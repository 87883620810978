import React, { useState, createContext, useContext } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import theme, { responsiveTheme } from '../Theme/theme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Header from './Common/Header/Header';
import { CssBaseline, Box, makeStyles } from '@material-ui/core';
import Devis from './PagesComponent/Devis/Devis';
import Footer from './Common/Footer';
import { RecrutementProvider } from '../providers/recrutement';

const styles = makeStyles((theme) => ({
  offset: {
    marginTop: theme.spacing(10.75),
    '@media(max-width:576px)': {
      marginTop: 55,
    },
  },
}));

const isBrowser = typeof window !== 'undefined';
const ThemeContext = createContext({
  isBodyShift: false,
  devis: false,
  toggle: () => {},
});

const Container = (props: any) => {
  const { children } = props;
  const classes = styles();
  const [open, setOpen] = useState<boolean>(false);
  const [openDevis, setOpenDevis] = useState<boolean>(false);
  const handleClose = () => setOpen((prev) => !prev);
  const toggleDevisState = () => {
    setOpenDevis(!openDevis);
  };
  const viewportWidth = useMediaQuery('(min-width:1281px)');

  return (
    <ThemeProvider theme={viewportWidth ? theme : responsiveTheme}>
      <ThemeContext.Provider
        value={{
          isBodyShift: open,
          devis: openDevis,
          toggle: toggleDevisState,
        }}
      >
        <CssBaseline />
        <Box>
          <Header open={open} handleClose={handleClose} />
          <Box className={classes.offset}>{children}</Box>
        </Box>
        <Footer handleClose={toggleDevisState} />
        <Devis open={openDevis} handleClose={toggleDevisState} />
      </ThemeContext.Provider>
    </ThemeProvider>
  );
};

export const useTheme = () => useContext(ThemeContext);
export default Container;
