import React, { ChangeEvent, useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Dialog,
  Button,
  IconButton,
  MenuItem,
  FormControlLabel,
  Checkbox,
  InputAdornment,
} from '@material-ui/core';
import styles from './style';
import FileUpload from '../../Common/FileUpload/FileUpload';
import IconClose from '@material-ui/icons/Close';
import { TransitionLink as Link } from '../../Common/TransitionLink/TransitionLink';
import IconPhone from '../../Common/Icons/IconPhone';
import IconMail from '../../Common/Icons/IconMail';
import SocialNetwork from '../../Common/SocialNetwork';
import imgDevis from '../../../images/img_devis.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import getFileBase64 from '../../../Services/getFileBase64';
import isEmail from 'validator/es/lib/isEmail';
import SnackBar from '@material-ui/core/Snackbar';
import { askDevis } from '../../../api/sendMail';
import { checkPhoneNumber } from '../../../Services/validation';
import { number } from 'prop-types';

interface StateInterface {
  nom: string;
  prenom: string;
  societe: string;
  tel: string;
  message: string;
  besoin: string;
  file: any[];
  budget: string;
  technos: string;
  fonction: string;
  mail: string;
  isAccept: boolean;
}

function Devis({ open, handleClose }: any) {
  const classes = styles();

  const [input, setInput] = useState<StateInterface>({
    nom: '',
    prenom: '',
    societe: '',
    tel: '',
    message: '',
    besoin: '',
    file: [],
    budget: '',
    technos: '',
    fonction: '',
    mail: '',
    isAccept: false,
  });

  const [snackBar, setSnackBar] = useState(false);
  const [messageSnackBar, setMessageSnackBar] = useState('');

  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, [event.target.name]: event.target.value });
  };

  const handleChangeCheckBox = (event: ChangeEvent<{}>, isAccept: boolean) => {
    setInput({ ...input, isAccept });
  };

  const handleChangeFile = (filesBefore: File[]) => {
    getFileBase64(filesBefore, filesAfter => {
      let size = 0;
      filesAfter.forEach((fichier: any) => {
        size += fichier.file.size;
      });
      if (size > 20000000) {
        setMessageSnackBar('fichier volumineux (taille max 20MB)');
        setSnackBar(true);
      } else {
        const formattedFile = [
          ...filesAfter.map((fichier: any) => ({
            name: fichier.name,
            content: fichier.base64.replace(`data:${fichier.type};base64,`, ''),
            type: fichier.type,
            size: fichier.file.size,
          })),
        ];
        setInput({ ...input, file: formattedFile });
      }
    });
  };

  const handleCloseSnackBar = () => {
    setSnackBar(false);
  };

  const handleOnSubmit = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!isEmail(input.mail)) {
      setMessageSnackBar("L'email n'est pas valide.");
      setSnackBar(true);
      return;
    }
    if (!checkPhoneNumber(input.tel)) {
      setMessageSnackBar("Le numéro de téléphone n'est pas valide");
      setSnackBar(true);
      return;
    }

    const {
      nom,
      prenom,
      societe,
      tel,
      message,
      besoin,
      file,
      budget,
      technos,
      fonction,
      mail,
    } = input;

    const initState = () => {
      setInput({
        nom: '',
        prenom: '',
        societe: '',
        tel: '',
        mail: '',
        message: '',
        besoin: '',
        file: [],
        budget: '',
        technos: '',
        fonction: '',
        isAccept: true,
      });
    };

    askDevis(
      {
        nom,
        prenom,
        societe,
        tel,
        message,
        besoin,
        file,
        budget,
        technos,
        fonction,
        mail,
      },
      setSnackBar,
      setMessageSnackBar,
      initState,
    );
  };

  const url = typeof window !== 'undefined' ? window.location.href : '';
  const domaine_ext = url.includes(".fr");

  return (
    <Dialog
      fullScreen
      open={open}
      PaperProps={{ classes: { root: classes.modal } }}
    // onClose={SnackBar}
    >
      <Box className={classes.modalContent}>
        <IconButton
          edge="end"
          className={classes.buttonClose}
          onClick={handleClose}
        >
          <IconClose />
        </IconButton>
        <Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box display="flex" justifyContent="space-between">
              <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                className={classes.siteBrand}
              >
                <img
                  width={477}
                  src={imgDevis}
                  alt="Hairun technology"
                  title="Hairun technology"
                />
              </Box>
              <Box className={classes.formWrapper}>
                <Typography variant="h4" className="titreSection">
                  Demandez un <span>devis</span>
                </Typography>
                <form onSubmit={handleOnSubmit}>
                  <Box className={classes.flexBox}>
                    <TextField
                      variant="outlined"
                      placeholder="Nom *"
                      required={true}
                      onChange={handleChangeInput}
                      value={input.nom}
                      name="nom"
                    />
                    <TextField
                      variant="outlined"
                      placeholder="Prénom *"
                      required={true}
                      onChange={handleChangeInput}
                      value={input.prenom}
                      name="prenom"
                    />
                  </Box>

                  <Box className={classes.flexBox}>
                    <TextField
                      variant="outlined"
                      placeholder="Société *"
                      required={true}
                      onChange={handleChangeInput}
                      value={input.societe}
                      name="societe"
                    />
                    <TextField
                      variant="outlined"
                      placeholder="Fonction *"
                      required={true}
                      onChange={handleChangeInput}
                      value={input.fonction}
                      name="fonction"
                    />
                  </Box>

                  <Box className={classes.flexBox}>
                    <TextField
                      variant="outlined"
                      placeholder="Téléphone *"
                      required={true}
                      onChange={handleChangeInput}
                      value={input.tel}
                      name="tel"
                    />
                    <TextField
                      variant="outlined"
                      placeholder="Email *"
                      required={true}
                      onChange={handleChangeInput}
                      value={input.mail}
                      name="mail"
                    />
                  </Box>

                  <Box className={classes.flexBox}>
                    <TextField
                      variant="outlined"
                      placeholder="Votre besoin"
                      label="Votre besoin"
                      SelectProps={{ IconComponent: ExpandMoreIcon }}
                      defaultValue="application-web"
                      select
                      onChange={handleChangeInput}
                      value={input.besoin}
                      name="besoin"
                      InputLabelProps={{
                        style: {
                          color: '#ffffff8c',
                          fontSize: '0.825rem',
                          paddingTop: 5,
                        },
                      }}
                    >
                      <MenuItem value="application-web">
                        Application web
                      </MenuItem>
                      <MenuItem value="application-mobile">
                        Application mobile
                      </MenuItem>
                      <MenuItem value="Datascience">Datascience & BI</MenuItem>
                      <MenuItem value="Infogérance">Infogérance & DevOps</MenuItem>
                      <MenuItem value="Testing-QA">Testing QA</MenuItem>
                      <MenuItem value="Autres">Autres</MenuItem>
                    </TextField>
                    <TextField
                      variant="outlined"
                      placeholder="Votre budget"
                      type="number"
                      inputProps={{ min: "0", max: "10000000", step: "100",   
                       startAdornment: (
                        <InputAdornment
                          className={classes.InputAdornment}
                          position="start"
                        >
                          €
                        </InputAdornment>
                      ), }}
                      onChange={handleChangeInput}
                      value={input.budget}
                      name="budget"
                    />
                  </Box>

                  <Box className={classes.flexBox}>
                    <TextField
                      variant="outlined"
                      placeholder="Message *"
                      multiline
                      rows={10}
                      fullWidth
                      required={true}
                      onChange={handleChangeInput}
                      value={input.message}
                      name="message"
                    />
                  </Box>
                  <Box className={classes.flexBox}>
                    <FileUpload
                      className={classes.uploadFile}
                      maxFiles={2}
                      onChangeInputFile={handleChangeFile}
                    />
                  </Box>
                  <Box className={classes.flexBox}>
                    <FormControlLabel
                      className={classes.formControlLabel}
                      control={<Checkbox name="" />}
                      value={input.isAccept}
                      onChange={handleChangeCheckBox}
                      label="En soumettant ce formulaire, j'accepte que les informations saisies soient exploitées dans le cadre de la demande de devis et de la relation commerciale qui peut en découler."
                    />
                  </Box>
                  <Box className={classes.flexBox}>
                    <Button
                      color="default"
                      variant="contained"
                      disableElevation
                      className={classes.btnSubmit}
                      type="submit"
                      disabled={!input.isAccept}
                    >
                      Envoyer
                    </Button>
                  </Box>
                </form>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              className={classes.menuBottom}
            >
              <Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                >
                  <Typography variant="body2">HaiRun Technology</Typography>
                  <Typography variant="body2">Immeuble Sodiama</Typography>
                  <Typography variant="body2">
                    Rocade Marais Masay Ankorondrano - Antananarivo Madagascar
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  className={classes.mt2}
                >
                  <Typography variant="body2">HaiRun Technology France</Typography>
                  <Typography variant="body2">59 rue des Petits Champs 75001 PARIS</Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                className={classes.contentFlexLink}
              >
                <Link
                  href="tel:+261 34 70 482 73"
                  color="inherit"
                  underline="none"
                  variant="body2"
                  gutterBottom
                >
                  <IconPhone
                    viewBox="0 0 18.569 18.568"
                    style={{ marginRight: 12, fontSize: 16 }}
                  />
                  +261 34 70 482 73
                </Link>
                <>
                  <Link
                    href="tel:+33 1 42 44 12 65"
                    color="inherit"
                    underline="none"
                    variant="body2"
                    gutterBottom
                  >
                    <IconPhone
                      viewBox="0 0 18.569 18.568"
                      style={{ marginRight: 12, fontSize: 16, opacity: 0 }}
                    />
                    +33 1 42 44 12 65
                  </Link>
                  <Link
                    href="tel:+33 7 51 43 14 36"
                    color="inherit"
                    underline="none"
                    variant="body2"
                    gutterBottom
                  >
                    <IconPhone
                      viewBox="0 0 18.569 18.568"
                      style={{ marginRight: 12, fontSize: 16, opacity: 0 }}
                    />
                    +33 7 51 43 14 36
                  </Link></>
                <Box className={classes.mt2}>
                  <Link
                    href="mailto:contact@hairun-technology.com"
                    color="inherit"
                    underline="none"
                    variant="body2"
                  >
                    <IconMail
                      viewBox="0 0 20.862 14.725"
                      style={{ marginRight: 12, fontSize: 16 }}
                    />
                    contact@hairun-technology.com
                  </Link>
                  <Link
                    href="mailto:contact@hairun-technology.fr"
                    color="inherit"
                    underline="none"
                    variant="body2"
                  >
                    <IconMail
                      viewBox="0 0 20.862 14.725"
                      style={{ marginRight: 12, fontSize: 16, opacity: 0 }}
                    />
                    contact@hairun-technology.fr
                  </Link>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
              >
                <Typography variant="body2" className={classes.textSocialMedia}>
                  Suivez-nous sur :
                </Typography>
                <SocialNetwork htmlColor="#fff" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <SnackBar
        open={snackBar}
        message={messageSnackBar}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={handleCloseSnackBar}
        onClick={handleCloseSnackBar}
        action={
          <IconButton onClick={handleClose} size="small" color="inherit">
            <IconClose />
          </IconButton>
        }
      />
    </Dialog>
  );
}

export default Devis;
