import React from 'react';
import { Box } from '@material-ui/core';
import styles from './style';
import classnames from 'classnames';

const Layout = (props: any) => {
  const { children } = props;
  const classes = styles();
  // const isBodyShift = useTheme().isBodyShift;

  return (
    <Box
      className={classnames(
        props.className,
        classes.container,
        // isBodyShift ? classes.containerShift : '',
      )}
    >
      {children}
    </Box>
  );
};

export default Layout;
