import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  modal: {
    display: 'flex',
    backgroundColor: '#0E0C38',
  },
  modalContent: {
    position: 'relative',
    color: '#FFF',
    width: '90%',
    // minHeight: '100%',
    padding: '100px 0 30px',
    marginLeft: 'auto',
    marginRight: 'auto',
    '&>div': {
      margin: 'auto',
      height: '100%',
      '&>div': {
        height: '100%',
        paddingBottom: theme.spacing(4),
        maxWidth: 1460,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    '& h4': {
      fontWeight: 600,
      marginBottom: theme.spacing(5),
      '& span': {
        color: '#D34246',
        fontWeight: '700 !important',
      },
    },
  },
  buttonClose: {
    position: 'absolute',
    top: 15,
    right: 0,
    color: 'inherit',
  },
  flexBox: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '@media (max-width: 768px)': {
      flexWrap: 'wrap',
    },
    '& input::placeholder': {
      color: '#FFF',
    },
    '&>*': {
      flex: '1 1 50%',
      marginBottom: theme.spacing(3),
      '@media (max-width: 768px)': {
        flex: '1 1 100%',
      },
    },
    '*&>*:not(:last-child)': {
      marginRight: theme.spacing(3),
      '@media (max-width: 768px)': {
        marginRight: 0,
      },
    },

    '& input, & textarea, & .MuiSelect-selectMenu': {
      color: '#fff',
      fontSize: '0.825rem',
    },
    '& input': {
      paddingTop: 15,
      paddingBottom: 15,
    },
    '& .MuiSelect-selectMenu': {
      paddingTop: 13,
      paddingBottom: 13,
    },
    '& fieldset': {
      color: '#fff',
      borderColor: '#fff!important',
      '& legend': {
        color: '#fff',
      },
    },
    '& svg': {
      color: '#fff',
      right: 5,
    },
    '&::placeholder': {
      color: '#fff',
    },
  },
  InputAdornment: {
    '& p': {
      color: '#fff',
      fontSize: '0.825rem',
    },
  },
  uploadFile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    textAlign: 'center',
    color: '#FFF',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    position: 'relative',
    border: '1px dashed #fff',
    borderRadius: 4,
    paddingTop: 0,
    paddingBottom: 0,
  },
  formControl: {
    position: 'relative',
    '& > div': {
      fontSize: '0.825rem',
      backgroundColor: 'transparent',
      marginTop: 0,
      border: '1px solid #fff',
      borderRadius: 4,
      position: 'relative',
      color: '#fff',
      '&:after': {
        display: 'inline-block',
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 10,
        margin: 'auto',
      },
    },
    '& label': {
      color: '#fff',
      left: 15,
      bottom: 0,
      top: 14,
      margin: 'auto',
      fontSize: '0.825rem',
      transform: 'none',
    },
    '& svg': {
      color: '#fff',
      right: 5,
    },
  },
  menuBottom: {
    '@media (max-width: 1200px)': {
      display: 'none',
    },
    '& a': {
      color: '#fff',
      textDecoration: 'none',
    },
  },
  telephoneFr: {
    marginBottom: theme.spacing(4),
  },
  textSocialMedia: {
    marginBottom: theme.spacing(4),
  },
  btnSubmit: {
    color: '#fff !important',
    backgroundColor: '#D34246 !important',
    width: '100%',
    letterSpacing: 1.25,
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: theme.spacing(3),
    '@media(max-width: 992px)': {
      width: 'auto',
      paddingLeft: 15,
      paddingRight: 15,
    },
    '&:hover, &:focus': {
      backgroundColor: '#D34246 !important',
      opacity: '0.75',
    },
  },
  siteBrand: {
    flex: 1,
    maxWidth: 703,
    '@media (max-width: 1400px)': {
      maxWidth: 500,
    },
    '@media (max-width: 1200px)': {
      display: 'none',
    },
    '& img': {
      width: '100%',
    },
  },
  formWrapper: {
    width: 577,
    '@media (max-width: 1400px)': {
      width: '100%',
    },
  },
  formControlLabel: {
    alignItems: 'flex-start',
    display: 'flex',
    '& .MuiCheckbox-root': {
      paddingTop: 0,
    },
    '& svg': {
      color: '#fff',
    },
  },
  contentFlexLink:{
    '& a':{
      display:'flex',
      alignItems: 'center'
    }
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
}));
