import React from 'react';
import {
  Box,
  Typography,
  Link,
  Button,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt';

import Layout from '../Layout';
import { Link as InternalLink } from 'gatsby';

import styles from './style';

import IconPhone from '../Icons/IconPhone';
import IconMail from '../Icons/IconMail';

import SocialNetwork from '../SocialNetwork';
import { useTheme } from '../../Container';
import classnames from 'classnames';

const Footer = (props: any) => {
  const classes = styles();
  const devis = useTheme();
  const handleDevisState = () => {
    devis.toggle();
  };

  const url = typeof window !== 'undefined' ? window.location.href : '';
  const domaine_ext = url.includes('.fr');

  return (
    <Box className={classes.footer} bgcolor="#E0363A">
      <Layout>
        <Box className={classes.footerContainer}>
          <Box className={classes.footerLogoWrapper}>
            <Box className={classes.address}>
              <Typography variant="h4" className={classes.titreFooter}>
                Contactez-nous
              </Typography>
              <Box
                className={classnames(classes.subContentAdress, classes.mb16)}
              >
                <Typography variant="body2">
                  HaiRun Technology France
                </Typography>
                <Typography variant="body2">
                  59 rue des Petits Champs 75001 PARIS
                </Typography>
              </Box>
              <Box className={classes.subContentAdress}>
                <Typography variant="body2">
                  HaiRun Technology Madagascar
                </Typography>
                <Typography variant="body2">Immeuble Sodiama</Typography>
                <Typography variant="body2">
                  Rocade Marais Masay Ankorondrano - Antananarivo
                </Typography>
              </Box>
            </Box>
            <Box className={classes.contact}>
              <Link
                href="tel:+33 1 42 44 12 65"
                color="inherit"
                underline="none"
                variant="body2"
                gutterBottom
                className={classes.telephone}
              >
                <IconPhone
                  viewBox="0 0 18.569 18.568"
                  style={{ marginRight: 12, fontSize: 16 }}
                />
                +33 1 42 44 12 65
              </Link>
              <Link
                href="tel:+33 7 51 43 14 36"
                color="inherit"
                underline="none"
                variant="body2"
                gutterBottom
              >
                <IconPhone
                  viewBox="0 0 18.569 18.568"
                  style={{ marginRight: 12, fontSize: 16, opacity: 0 }}
                />
                +33 7 51 43 14 36
              </Link>
              <Link
                href="tel:+261 34 70 482 73"
                color="inherit"
                underline="none"
                variant="body2"
                gutterBottom
                className={classes.telephoneFr}
              >
                <IconPhone
                  viewBox="0 0 18.569 18.568"
                  style={{ marginRight: 12, fontSize: 16, opacity: 0 }}
                />
                +261 34 70 482 73
              </Link>

              <Link
                href="mailto:contact@hairun-technology.fr"
                color="inherit"
                underline="none"
                variant="body2"
              >
                <IconMail
                  viewBox="0 0 20.862 14.725"
                  style={{ marginRight: 12, fontSize: 16 }}
                />
                contact@hairun-technology.fr
              </Link>
              <Link
                href="mailto:contact@hairun-technology.com"
                color="inherit"
                underline="none"
                variant="body2"
              >
                <IconMail
                  viewBox="0 0 20.862 14.725"
                  style={{ marginRight: 12, fontSize: 16, opacity: 0 }}
                />
                contact@hairun-technology.com
              </Link>
            </Box>
          </Box>
          <Box className={classes.footerMenu}>
            <Typography variant="h4" className={classes.titreFooter}>
              Nos services
            </Typography>
            <Box>
              <Typography component="ul">
                <Typography component="li">
                  <InternalLink to="/developpement-sur-mesure">
                    Développement sur mesure
                  </InternalLink>
                </Typography>
                <Typography component="li">
                  <InternalLink to="/infogerance">
                    Infogérance et DevOps
                  </InternalLink>
                </Typography>
                <Typography component="li">
                  <InternalLink to="/datascientist">
                    Data science et Business Intelligence (BI)
                  </InternalLink>
                </Typography>
              </Typography>
            </Box>
            <Box>
              <Button
                color="secondary"
                variant="outlined"
                endIcon={<ArrowRightAlt />}
                onClick={handleDevisState}
              >
                Obtenir un devis
              </Button>
            </Box>
          </Box>
          <Box
            className={classnames(classes.footerSocialNet, classes.socialNet)}
          >
            <Typography variant="h4" className={classes.titreFooter}>
              Restez informé !
            </Typography>
            <TextField
              className={classes.input}
              placeholder="Votre adresse email"
              type="email"
              color="secondary"
              variant="filled"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button size="medium">S'inscrire</Button>
                  </InputAdornment>
                ),
              }}
            />
            <FormControlLabel
              className={classes.formControlLabel}
              control={<Checkbox name="" />}
              label="En soumettant ce formulaire, j'accepte que les informations saisies soient exploitées dans le cadre de la demande de devis et de la relation commerciale qui peut en découler."
            />
          </Box>
          <Box className={classes.formSuivezNous}>
            <Typography variant="subtitle1" className={classes.textSocialMedia}>
              Suivez-nous sur :
            </Typography>
            <SocialNetwork htmlColor="#fff" className={classes.socialMedia} />
          </Box>
        </Box>
      </Layout>
    </Box>
    /* <Box className={classes.footer} bgcolor="#E0363A">
      <Layout>
        <Box className={classes.footerContainer}>
          <Box className={classes.footerLogoWrapper}>
            <Box className={classes.address}>
              <Typography variant="h4" className={classes.titreFooter}>
                Contactez-nous
              </Typography>
              {!domaine_ext ? <Box className={classnames(classes.subContentAdress, classes.mb16)}>
                <Typography variant="body2">HaiRun Technology Madagascar</Typography>
                <Typography variant="body2">Immeuble Sodiama</Typography>
                <Typography variant="body2">
                  Rocade Marais Masay Ankorondrano - Antananarivo
                </Typography>
              </Box> :
                <Box className={classnames(classes.subContentAdress, classes.mb16)}>
                  <Typography variant="body2">HaiRun Technology France</Typography>
                  <Typography variant="body2">59 rue des Petits Champs 75001 PARIS</Typography>
                </Box>
              }
            </Box>
            <Box className={classes.contact}>
              {!domaine_ext ?
                <Link
                  href="tel:+261 34 70 482 73"
                  color="inherit"
                  underline="none"
                  variant="body2"
                  gutterBottom
                  className={classes.telephoneFr}
                >
                  <IconPhone
                    viewBox="0 0 18.569 18.568"
                    style={{ marginRight: 12, fontSize: 16 }}
                  />
                  +261 34 70 482 73
                </Link> :
                <>
                  <Link
                    href="tel:+33 1 42 44 12 65"
                    color="inherit"
                    underline="none"
                    variant="body2"
                    gutterBottom
                    className={classes.telephone}
                  >
                    <IconPhone
                      viewBox="0 0 18.569 18.568"
                      style={{ marginRight: 12, fontSize: 16 }}
                    />
                    +33 1 42 44 12 65
                  </Link>
                  <Link
                    href="tel:+33 7 51 43 14 36"
                    color="inherit"
                    underline="none"
                    variant="body2"
                    gutterBottom
                    className={classes.telephoneFr}
                  >
                    <IconPhone
                      viewBox="0 0 18.569 18.568"
                      style={{ marginRight: 12, fontSize: 16, opacity: 0 }}
                    />
                    +33 7 51 43 14 36
                  </Link></>
              }
              {!domaine_ext ? <Link
                href="mailto:contact@hairun-technology.com"
                color="inherit"
                underline="none"
                variant="body2"
              >
                <IconMail
                  viewBox="0 0 20.862 14.725"
                  style={{ marginRight: 12, fontSize: 16 }}
                />
                contact@hairun-technology.com
              </Link> :
                <Link
                  href="mailto:contact@hairun-technology.fr"
                  color="inherit"
                  underline="none"
                  variant="body2"
                >
                  <IconMail
                    viewBox="0 0 20.862 14.725"
                    style={{ marginRight: 12, fontSize: 16 }}
                  />
                  contact@hairun-technology.fr
                </Link>}
            </Box>
          </Box>
          <Box className={classes.footerMenu}>
            <Typography variant="h4" className={classes.titreFooter}>
              Nos services
            </Typography>
            <Box>
              <Typography component="ul">
                <Typography component="li">
                  <InternalLink to="/developpement-sur-mesure">
                    Développement sur mesure
                  </InternalLink>
                </Typography>
                <Typography component="li">
                  <InternalLink to="/infogerance">Infogérance & DevOps</InternalLink>
                </Typography>
                <Typography component="li">
                  <InternalLink to="/datascientist">
                    Data science & Business Intelligence (BI) 
                  </InternalLink>
                </Typography>
              </Typography>
            </Box>
            <Box>
              <Button
                color="secondary"
                variant="outlined"
                endIcon={<ArrowRightAlt />}
                onClick={handleDevisState}
              >
                Obtenir un devis
              </Button>
            </Box>
          </Box>
          <Box
            className={classnames(classes.footerSocialNet, classes.socialNet)}
          >
            <Typography variant="h4" className={classes.titreFooter}>
              Restez informé !
            </Typography>
            <TextField
              className={classes.input}
              placeholder="Votre adresse email"
              type="email"
              color="secondary"
              variant="filled"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button size="medium">S'inscrire</Button>
                  </InputAdornment>
                ),
              }}
            />
            <FormControlLabel
              className={classes.formControlLabel}
              control={<Checkbox name="" />}
              label="En soumettant ce formulaire, j'accepte que les informations saisies soient exploitées dans le cadre de la demande de devis et de la relation commerciale qui peut en découler."
            />
          </Box>
          <Box className={classes.formSuivezNous}>
            <Typography variant="subtitle1" className={classes.textSocialMedia}>
              Suivez-nous sur :
            </Typography>
            <SocialNetwork htmlColor="#fff" />
          </Box>
        </Box>
      </Layout>
    </Box>*/
  );
};

export default Footer;
