import React, { useState, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {
  Box,
  IconButton,
  Button,
  AppBar,
  AppBarProps,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';
import { TransitionLink } from '../TransitionLink/TransitionLink';
import { AnchorLink as Link } from 'gatsby-plugin-anchor-links';
import styles from './style';

import Logo from '../../../images/hairun-logo.svg';

import SortIcon from '@material-ui/icons/Sort';
import ArrowRight from '@material-ui/icons/ArrowRightAlt';

import Layout from '../Layout/Layout';
import classnames from 'classnames';

import { useTheme } from '../../Container';
import IconArrowRightAlt from '../Icons/IconArrowRightAlt';
import Menu from '../Menu/Menu';

const urlRecrutement = '/recrutement';

interface IHeader {
  handleClose(): void;
  open: boolean;
}

const Header = ({ handleClose, open }: IHeader) => {
  const classes = styles();
  const [color, setColor] = useState<AppBarProps['color']>();
  const [, setIsVisible] = useState<boolean>(false);
  const devis = useTheme();
  const handleDevisState = () => {
    devis.toggle();
  };
  const desktop = useMediaQuery('(min-width:640px)');

  useEffect(() => {
    window.addEventListener('scroll', handleBackground);
    return () => window.removeEventListener('scroll', handleBackground);
  });

  const handleBackground = () => {
    setColor(
      window.location.pathname !== '/'
        ? 'inherit'
        : window.pageYOffset >= 150
        ? 'inherit'
        : 'transparent', //l'ancient => #fff non compatible
    );

    window.pageYOffset >= 150 ? setIsVisible(true) : setIsVisible(false);
    return;
  };

  const isBrowser = typeof window !== 'undefined';

  return (
    <>
      <AppBar
        position="fixed"
        color={color}
        elevation={
          (isBrowser && window.pageYOffset >= 150) ||
          (isBrowser && window.location.pathname !== '/')
            ? 3
            : 0
        }
        className={
          isBrowser && window.location.pathname !== '/'
            ? classes.appbar
            : undefined
        }
      >
        <Layout className={classes.headerContainer}>
          <Box className={classnames(classes.header)}>
            <>
              <Box className={classes.siteBrand}>
                <TransitionLink to="/">
                  <img
                    width={141}
                    src={Logo}
                    alt="Hairun technology"
                    title="Hairun technology"
                  />
                </TransitionLink>
              </Box>
              <Box className={classes.buttonPlus}>
                <Link to={urlRecrutement} title="Recrutement" className={classes.btnLink}>
                  <Button
                    variant="outlined"
                    color="primary"
                    endIcon={<ArrowRight color="inherit" />}
                    className={classnames('classicButton')}
                  >
                    Rejoignez-nous
                  </Button>
                </Link>

                {desktop && <>Prêt à démarrer votre projet ?</>}
            
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDevisState}
                  disableElevation
                  endIcon={<IconArrowRightAlt viewBox="0 0 24 24" />}
                >
                  Obtenir un devis
                </Button>
              </Box>
            </>
            <Box className={classes.buttonMenu}>
              <Tooltip
                title="Menu"
                placement="left"
                classes={{ tooltip: classes.tooltip }}
              >
                {!open ? (
                  <IconButton onClick={handleClose} style={{ padding: 0 }}>
                    <SortIcon fontSize="large" htmlColor="#272727" />
                  </IconButton>
                ) : (
                  <IconButton onClick={handleClose} style={{ padding: 0 }}>
                    <CloseIcon htmlColor="#fff" />
                  </IconButton>
                )}
              </Tooltip>
            </Box>
          </Box>
        </Layout>
        {open ? <Menu open={open} handleClose={handleClose} /> : ''}
      </AppBar>
    </>
  );
};

export default Header;
