import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
    // maxWidth: 1364,
    margin: '0 auto',
    paddingLeft: 40,
    paddingRight: 40,
    '@media (max-width: 768px)': {
      paddingLeft: 15,
      paddingRight: 15,
    },
    '@media (max-width: 576px)': {
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
}));
