import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  menuLayout: {
    display: 'inline-block',
    width: '100%',
    height: '100vh',
    position: 'absolute',
    zIndex: 97,
    content: "''",
    backgroundColor: '#00000078',
  },
  menu: {
    color: theme.palette.common.white,
    position: 'absolute',
    right: 0,
    zIndex: 98,
    width: '100%',
    minHeight: '100vh',
    top: 0,
    paddingTop: 84,
    backgroundColor: '#C72F33FC',
    '& a': {
      display: 'block',
      width: '100%',
      textDecoration: 'none',
      color: 'inherit',
    },
    //'@media (max-width: 768px)': {
    // top: 88,
    //},
    //'@media (max-width: 576px)': {
    //top: 70,
    // },
  },
  menuWrapper: {
    flex: 1,
  },
  menuPaper: {
    width: 300,
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
  },
  list: {
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'flex-start',
    '@media (max-width: 992px)': {
      flexWrap: 'wrap',
    },
    '& .MuiListItem-dense': {
      paddingTop: 0,
      '@media (max-width: 992px)': {
        width: '100%',
      },
    },
    '& span': {
      fontSize: '1.5rem',
    },
  },
  listMenuTitre: {
    '& span': {
      color: '#fff',
      fontSize: '1.5rem',
      fontWeight: 700,
      '@media (max-width: 992px)': {
        fontSize: '2rem !important',
      },
      '@media (max-width: 768px)': {
        fontSize: '1.875rem !important',
      },
      '@media (max-width: 576px)': {
        fontSize: '1.5rem !important',
      },
    },
  },
  listMenuTitreSmall: {
    '& span': {},
  },
  nestedList: {
    display: 'block',
    '& ul li span': {
      fontSize: '1rem',
      color: '#fff',
      marginBottom: theme.spacing(2),
      display: 'block',
      '@media (max-width: 992px)': {
        fontSize: '1.250rem !important',
        marginBottom: theme.spacing(1),
      },
      '@media (max-width: 768px)': {
        fontSize: '1.125rem !important',
        marginBottom: 0,
      },
      '@media (max-width: 576px)': {
        fontSize: '1rem !important',
      },
    },
  },
  listMenuDense: {},
  textSocialMedia: {
    marginBottom: theme.spacing(2),
  },
  mailLinkMenu: {
    marginTop: theme.spacing(2),
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
    paddingTop: '3%',
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    height: 'calc(100vh - 84px)',
    overflowY: 'auto',
  },
  siteBrand: {
    alignItems: 'center',
    marginRight: theme.spacing(10),
    '@media (max-width: 1400px)': {
      maxWidth: 250,
    },
    '@media (max-width: 1200px)': {
      display: 'none',
    },
    '& a': {
      width: 'auto',
      marginRight: 'auto',
      marginLeft: 'auto',
      marginBottom: theme.spacing(3),
      '& img': {
        width: 250,
      },
    },
    '& img': {
      width: '100%',
    },
  },
  menuBottom: {
    '@media (max-width: 1200px)': {
      display: 'none',
    },
  },
  contentFlexLink:{
    '& a':{
      display:'flex',
      alignItems: 'center'
    }
  }
}));
