import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const IconCloud = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        id="Tracé_11131"
        data-name="Tracé 11131"
        d="M0,0H24V24H0Z"
        fill="none"
      />
      <path
        id="Tracé_11132"
        data-name="Tracé 11132"
        d="M19.35,10.04a7.492,7.492,0,0,0-14-2A6,6,0,0,0,6,20H19a4.986,4.986,0,0,0,.35-9.96ZM14,13v4H10V13H7l5-5,5,5Z"
        fill="#fff"
      />
    </SvgIcon>
  );
};

export default IconCloud;
