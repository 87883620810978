import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
} from '@material-ui/core';
import styles from './style';
import { TransitionLink as Link } from '../TransitionLink/TransitionLink';
import Layout from '../Layout';
import classnames from 'classnames';
import IconPhone from '../Icons/IconPhone';
import IconMail from '../Icons/IconMail';

import SocialNetwork from '../SocialNetwork';
import { TransitionLink } from '../TransitionLink/TransitionLink';
import Logo from '../../../images/hairun-logo.svg';
import imgMenu from '../../../images/element_menu.svg';

interface MenuProps {
  handleClose(): void;
  open: boolean;
}

const isBrowser = typeof window !== 'undefined';

const Menu = ({ handleClose }: MenuProps) => {
  const classes = styles();
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const domaine_ext = url.includes(".fr");
  return (
    <>
      <Box className={classes.menuLayout}></Box>
      <Box
        // onClose={handleClose}
        className={classes.menu}
        classes={{
          paper: classes.menuPaper,
        }}
      >
        <Layout className={classes.menuContainer}>
          <Box display="flex">
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              className={classes.siteBrand}
            >
              <TransitionLink to="/">
                <img
                  width={141}
                  src={Logo}
                  alt="Hairun technology"
                  title="Hairun technology"
                />
              </TransitionLink>
              <img
                width={477}
                src={imgMenu}
                alt="Hairun technology"
                title="Hairun technology"
              />
            </Box>
            <Box className={classes.menuWrapper}>
              <List className={classes.list} dense>
                <ListItem className={classes.nestedList}>
                  <ListItemText
                    className={classnames(
                      classes.listMenuTitre,
                      //classes.listMenuTitreSmall,
                    )}
                    primary="Entreprise"
                  />

                  <List dense className={classes.listMenuDense}>
                    <ListItem disableGutters>
                      <Link to="/a-propos">
                        <ListItemText primary="A propos" />
                      </Link>
                    </ListItem>
                    <ListItem disableGutters>
                      <Link to="/recrutement/">
                        <ListItemText primary="Nous rejoindre" />
                      </Link>
                    </ListItem>
                  </List>
                </ListItem>

                <ListItem className={classes.nestedList}>
                  <ListItemText
                    className={classes.listMenuTitre}
                    primary="Nos services"
                  />

                  <List dense>
                    <ListItem disableGutters>
                      <Link to="/developpement-sur-mesure">
                      <ListItemText primary="Développement sur mesure" />
                      </Link>
                    </ListItem>
                    {/* 
                    <ListItem disableGutters>
                      <Link to="/service/infogerance">
                        <ListItemText primary="Développement sur mesure" />
                      </Link>
                    </ListItem>
                    <ListItem disableGutters>
                      <Link to="/service/infrastructure-informatique-entreprise">
                        <ListItemText primary="Infrastructure IT" />
                      </Link>
                    </ListItem>
                    <ListItem disableGutters>
                      <Link to="/service/solution-e-commerce">
                        <ListItemText primary="Solution E-commerce" />
                      </Link>
                    </ListItem>
                    <ListItem disableGutters>
                      <Link to="/service/partenaire-qlik">
                        <ListItemText primary="Partenaire Qlik" />
                      </Link>
                    </ListItem>
                    <ListItem disableGutters>
                      <Link to="/service/api-backend">
                        <ListItemText primary="API / BackEnd" />
                      </Link>
                    </ListItem> 
                    <ListItem disableGutters>
                      <Link to="/service/projet-ia">
                        <ListItemText primary="Projets d'IA" />
                      </Link>
                    </ListItem>
                    */}
                    <ListItem disableGutters>
                      <Link to="/infogerance">
                        <ListItemText primary="Infogérance et DevOps" />
                      </Link>
                    </ListItem>
                    <ListItem disableGutters>
                      <Link to="/datascientist">
                        <ListItemText primary="Data science et Business Intelligence (BI) " />
                      </Link>
                    </ListItem>
                  </List>
                </ListItem>

                <ListItem button>
                  <Link to="/blog">
                    <ListItemText
                      className={classes.listMenuTitre}
                      primary="Blog"
                    />
                  </Link>
                </ListItem>

                <ListItem button>
                  <Link
                    to="/contact"
                    state={{
                      prevUrl: isBrowser ? window.location.pathname : '',
                    }}
                  >
                    <ListItemText
                      className={classes.listMenuTitre}
                      primary="Contact"
                    />
                  </Link>
                </ListItem>
              </List>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            className={classes.menuBottom}
          >
            <Box>
              <Box>
                <Typography variant="body2">HaiRun Technology</Typography>
                <Typography variant="body2">Immeuble Sodiama</Typography>
                <Typography variant="body2">
                  Rocade Marais Masay Ankorondrano - Antananarivo Madagascar
                </Typography>
              </Box>
              <Box className={classes. mailLinkMenu}>
                <Typography variant="body2">HaiRun Technology France</Typography>
                <Typography variant="body2">59 rue des Petits Champs 75001 PARIS</Typography>
              </Box>
            </Box>
      
            <Box className={classes.contentFlexLink}>
                <Link
                  href="tel:+261 34 70 482 73"
                  color="inherit"
                  underline="none"
                  variant="body2"
                  gutterBottom
                >
                  <IconPhone
                    viewBox="0 0 18.569 18.568"
                    style={{ marginRight: 12, fontSize: 16 }}
                  />
                  +261 34 70 482 73
                </Link>
                <>
                  <Link
                    href="tel:+33 1 42 44 12 65"
                    color="inherit"
                    underline="none"
                    variant="body2"
                    gutterBottom
                  >
                    <IconPhone
                      viewBox="0 0 18.569 18.568"
                      style={{ marginRight: 12, fontSize: 16, opacity: 0 }}
                    />
                    +33 1 42 44 12 65
                  </Link>
                  <Link
                    href="tel:+33 7 51 43 14 36"
                    color="inherit"
                    underline="none"
                    variant="body2"
                    gutterBottom
                  >
                    <IconPhone
                      viewBox="0 0 18.569 18.568"
                      style={{ marginRight: 12, fontSize: 16, opacity: 0 }}
                    />
                    +33 7 51 43 14 36
                  </Link></>
                <Link
                  href="mailto:contact@hairun-technology.com"
                  color="inherit"
                  underline="none"
                  variant="body2"
                  className={classes.mailLinkMenu}
                >
                  <IconMail
                    viewBox="0 0 20.862 14.725"
                    style={{ marginRight: 12, fontSize: 16 }}
                  />
                  contact@hairun-technology.com
                </Link>
                <Link
                  href="mailto:contact@hairun-technology.fr"
                  color="inherit"
                  underline="none"
                  variant="body2"
                >
                  <IconMail
                    viewBox="0 0 20.862 14.725"
                    style={{ marginRight: 12, fontSize: 16, opacity: 0 }}
                  />
                  contact@hairun-technology.fr
                </Link>
            </Box>
            <Box>
              <Typography variant="body2" className={classes.textSocialMedia}>
                Suivez-nous sur :
              </Typography>
              <SocialNetwork htmlColor="#fff" />
            </Box>
          </Box>
        </Layout>
      </Box>
    </>
  );
};

export default Menu;
