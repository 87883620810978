import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  footer: {
    paddingTop: 100,
    paddingBottom: 100,
    color: theme.palette.background.paper,
    '@media (max-width: 1200px)': {
      paddingTop: 50,
      paddingBottom: 50,
    },
    '@media (max-width: 992px)': {
      paddingTop: 25,
      paddingBottom: 25,
    },
    '@media (max-width: 768px)': {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    '& h2': {
      fontSize: '1.875rem',
    },
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    // height: 260,
    '@media (max-width: 1400px)': {
      flexWrap: 'wrap',
    },
    '&>div': {
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 20,
      paddingRight: 20,
      '@media (max-width: 576px)': {
        width: '100%',
      },
    },
  },
  footerSocialNet: {
    '@media (max-width: 1400px)': {
      width: '100% !important',
      marginTop: 30,
      marginBottom: 20,
      '& > div, & > label': {
        maxWidth: '70%',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      '& h4': {
        textAlign: 'center',
      },
    },
    '@media (max-width: 576px)': {
      '& button': {
        width: 'auto !important',
      },
      '& > div, & > label': {
        maxWidth: 320,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
  },
  footerLogoWrapper: {
    '@media (max-width: 992px)': {
      width: '50%',
      paddingLeft: 10,
      paddingRight: 10,
    },
    '@media (max-width: 576px)': {
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0,
      marginBottom: 20,
      textAlign: 'center',
    },
  },
  logoFooter: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderRadius: 15,
    textAlign: 'center',
    '@media (max-width: 992px)': {
      maxWidth: 'fit-content',
      paddingLeft: 15,
      paddingRight: 15,
    },
    '@media (max-width: 576px)': {
      margin: '0 auto 20px',
    },
    '& img': {
      maxHeight: 50,
    },
  },
  titreFooter: {
    marginBottom: theme.spacing(4),
    fontSize: '1.250rem',
    fontFamily: 'ubuntu',
  },
  address: {
    '& p': {
      lineHeight: 1.8,
    },
  },
  contact: {
    '&>a': {
      display: 'flex',
      alignItems: 'center',
      '@media (max-width: 576px)': {
        justifyContent: 'center',
      },
    },
  },
  telephone: {
    marginTop: theme.spacing(4),
  },
  telephoneFr: {
    marginBottom: theme.spacing(4),
  },
  footerMenu: {
    '@media (max-width: 992px)': {
      width: '50%',
      paddingLeft: 10,
      paddingRight: 10,
    },
    '@media (max-width: 576px)': {
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0,
      textAlign: 'center',
    },
    '& ul': {
      padding: 0,
      listStyle: 'none',
    },
    '& li': {
      marginBottom: theme.spacing(2),
    },
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
      fontSize: '0.875rem',
    },
    '& button': {
      borderRadius: 4,
      marginTop: theme.spacing(3),
      backgroundColor: 'transparent',
      boxShadow: 'none',
      transition: '50ms linear',
      color: '#fff',
      borderColor: '#fff',
      '&:hover': {
        color: '#fff',
        borderColor: '#fff',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        opacity: 0.75,
        paddingLeft: 33,
        paddingRight: 33,
        marginRight: 0,
        marginLeft: 10,
        transition: '50ms linear',
      },
      '& svg': {
        transition: '50ms linear',
      },
      '&:hover svg': {
        transition: '50ms linear',
        transform: 'translateX(5px)',
      },
    },
  },
  socialNet: {
    '@media (max-width: 992px)': {
      width: '100%',
      marginTop: theme.spacing(4),
    },
    '@media (max-width: 576px)': {
      textAlign: 'center',
    },
    '& .MuiFilledInput-root': {
      backgroundColor: theme.palette.background.paper,
      borderRadius: 0,
    },
    '& input': {
      padding: '14px 10px',
    },
  },
  input: {
    '& .MuiFilledInput-adornedEnd': {
      paddingRight: 0,
      overflow: 'hidden',
      backgroundColor: 'transparent !important',
    },
    '& input': {
      padding: '14px 25px',
      color: '#fff',
      fontSize: '0.825rem',
      border: '1px solid #fff',
      borderRadius: 4,
      marginRight: 10,
    },
    '& button': {
      width: 120,
      padding: '12px 8px',
      color: theme.palette.background.paper,
      fontWeight: 400,
      backgroundColor: '#EBB32A',
      borderRadius: 4,
      transition: 'background ease .2s',
      '&:hover': {
        backgroundColor: '#ffb300',
      },
    },
  },
  divider: {
    backgroundColor: '#707070',
    '@media (max-width: 992px)': {
      marginTop: 10,
    },
  },
  socialMedia: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    '@media (max-width: 576px)': {
      justifyContent: 'center',
    },
    '&>*': {
      marginRight: theme.spacing(4),
    },
    '& svg': {
      fontSize: '2rem',
      transition: 'color ease 0.5s',
      color: '#fff',
    },
    '& a': {
      marginRight: theme.spacing(3),
      '&:first-of-type:hover svg': {
        color: '#4267B2',
      },
      '&:nth-of-type(2):hover svg': {
        color: '#00ACED',
      },
      '&:nth-of-type(3):hover svg': {
        color: '#0077B5',
      },
      '&:nth-of-type(4):hover svg': {
        color: '#CD2029',
      },
      '& path': {
        fill: '#fff',
      },
    },
  },
  formSuivezNous: {
    '@media(max-width: 1400px)': {
      width: '100% !important',
      alignItems: 'center',
      marginTop: 30,
      marginBottom: 20,
    },
  },
  textSocialMedia: {
    fontSize: '0.75rem',
    marginBottom: theme.spacing(2),
    '@media (max-width: 992px)': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    '& path': {
      fill: '#fff',
    },
  },
  formControlLabel: {
    alignItems: 'flex-start',
    display: 'flex',
    marginTop: theme.spacing(4),
    '& .MuiCheckbox-root': {
      paddingTop: 0,
      paddingLeft: 0,
    },
    '& svg': {
      color: '#fff',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '0.75rem',
    },
    '& span': {
      fontSize: '0.75rem',
    },
    '& span + span': {},
  },
  subContentAdress :{
    display: 'flex',
    flexDirection: 'column'
  },
  mb16:{
    marginBottom: 16
  },
}));
