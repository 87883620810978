import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  appbar: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'none',
    borderBottom: '1px solid rgb(204 204 204 / 0.25)',
  },
  headerContainer: {
    backgroundColor: '#fff',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto',
    paddingTop: 15.5,
    paddingBottom: 15.5,
    zIndex: 10,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '@media (min-width: 768px)': {
      justifyContent: 'space-between',
    },
    '@media (max-width: 576px)': {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  headerShift: {
    marginLeft: 190,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  buttonMenu: {
    borderRadius: '50%',
    marginLeft: theme.spacing(4),
    position: 'relative',
    zIndex: 99,
    '@media (max-width: 576px)': {
      marginLeft: 10,
    },
  },
  tooltip: {
    padding: theme.spacing(1.5, 2),
    fontSize: '1rem',
    backgroundColor: theme.palette.primary.light,
    borderRadius: 0,
    textTransform: 'uppercase',
  },
  siteBrand: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 'auto',
    '& img': {
      maxHeight: 50,
      '@media (max-width: 576px)': {
        maxHeight: 35,
      },
    },
  },
  buttonPlus: {
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
    color: '#272727',
    '@media (max-width: 992px)': {
      display: 'none',
    },
    '@media (max-width: 768px)': {
      fontSize: '0.825rem',
    },
    
    '&>button': {
      // height: 40,
      paddingLeft: 28,
      paddingRight: 28,
      marginLeft: 15,
      marginRight: 5,
      fontSize: '0.875rem',
      fontWeight: 500,
      transition: '50ms linear',
      borderRadius: 4,
      '@media (max-width: 576px)': {
        fontSize: '0.725rem',
        paddingLeft: 10,
        paddingRight: 10,
        marginLeft: 10,
        marginRight: 0,
      },
      '@media (max-width: 480px)': {
        fontSize: '0.65rem',
      },
      '&:hover': {
        opacity: 0.75,
        paddingLeft: 33,
        paddingRight: 33,
        marginRight: 0,
        marginLeft: 10,
        transition: '50ms linear',
        '@media (max-width: 576px)': {
          paddingLeft: 10,
          paddingRight: 10,
        },
      },
      '& svg': {
        transition: '50ms linear',
        '@media (max-width: 576px)': {
          display: 'none',
        },
      },
      '&:hover svg': {
        transition: '50ms linear',
        transform: 'translateX(5px)',
      },
    },
  },
  socialMedia: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: theme.spacing(2),
    '& a': {
      marginRight: theme.spacing(3),
      '& svg': {
        fontSize: 20,
        transition: 'color ease 0.5s',
      },
      '&:first-of-type:hover svg': {
        color: '#4267B2',
      },
      '&:nth-of-type(2):hover svg': {
        color: '#00ACED',
      },
      '&:nth-of-type(3):hover svg': {
        color: '#0077B5',
      },
      '&:nth-of-type(4):hover svg': {
        color: '#CD2029',
      },
    },
  },
  btnLink:{
    marginRight: 14,
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
    },
    '& button': {
      fontSize: '0.875rem',
      textDecoration: 'none',
      borderRadius: 4,
      borderColor: '#D34246',
      color: '#fff',
      boxShadow: 'none',
      backgroundColor: '#D34246',
      transition: '50ms ease-in',
      '&:hover': {
        paddingLeft: 20,
        paddingRight: 20,
        backgroundColor: '#D34246',
        borderColor: '#D34246',
        opacity: 0.75,
        transition: '50ms linear'
      },
      '& svg': {
        transition: '50ms ease-in',
      },
      '&:hover svg': {
        transition: '50ms ease-in',
        transform: 'translateX(5px)',
      },
    },
  }
}));
