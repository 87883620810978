import React from 'react';
import RemoveCircle from '@material-ui/icons/RemoveCircle';
import iconpdf from '../../../images/icon_pdf.svg';
import iconword from '../../../images/icon_ms_word.svg';
import { Box, IconButton } from '@material-ui/core';

interface IFIlePreview {
  file: File;
  key: number;
  handleRemove(index: number): void;
}

const FilePreview = (props: IFIlePreview) => {
  const { file, key, handleRemove } = props;
  const handleRemoveElement = () => {
    handleRemove(key);
  };
  return (
    <Box css={{ display: 'flex', marginTop: 16 }}>
      {file.type.startsWith('image/') && file.preview.type === 'image' ? (
        <Box display="flex" alignItems="center">
          <img
            src={file.preview.url}
            alt={file.id}
            key={key}
            style={{
              maxWidth: '15%',
              objectFit: 'cover',
            }}
          />
          <Box
            ml={2}
            css={{ maxWidth: 400 }}
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {file.name}
          </Box>
        </Box>
      ) : (
        <Box key={key} display="flex" alignItems="center">
          {file.extension === 'pdf' ? (
            <img width={25} src={iconpdf} alt="icon pdf" style={{ marginRight: 12 }} />
          ) : (
            <img width={25} src={iconword} alt="icon word" style={{ marginRight: 12 }} />
          )}
          <Box textOverflow="ellipsis">{file.name}</Box>
        </Box>
      )}
      <IconButton
        color="inherit"
        style={{ marginLeft: 'auto' }}
        onClick={handleRemoveElement}
      >
        <RemoveCircle />
      </IconButton>
    </Box>
  );
};

export default FilePreview;
