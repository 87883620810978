export default (files: File[], setFiles: (files: any[]) => void) => {
  if (files && files.length) {
    // Process each file
    let allFiles: any[] = [];
    for (let i = 0; i < files.length; i++) {
      let file = files[i];

      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        let fileInfo = {
          name: file.name,
          type: file.type,
          size: Math.round(file.size / 1000) + ' kB',
          base64: reader.result,
          file: file,
        };

        // Push it to the state
        allFiles.push(fileInfo);

        // If all files have been proceed
        if (allFiles.length === files!.length) {
          // Apply Callback function
          setFiles(allFiles);
        }
      }; // reader.onload
    } // for
  }
};
